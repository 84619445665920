.apply-container {
  height: 100vh;
  width: 100vw;
  background: var(--white);
  padding: 20px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .apply-box {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--boundry);

    .left {
      width: 45%;
      height: 80%;
      padding: 30px 0px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border: 3px solid var(--blue);
      p {
        max-width: 50%;
        text-align: center;
      }

      form {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        input,
        textarea {
          padding: 10px 20px;
          font-size: 16px;
          width: 90%;
          font-family: sans-serif;
          margin: calc(var(--unit) * 0.5);
          border-radius: 0px;
          border: none;
          background: transparent;
          border-bottom: 2px solid var(--blue);
          outline: none;
        }
        textarea {
          height: 16ch;
        }

        input[type="submit"] {
          padding: 8px 20px;
          color: var(--white);
          background: var(--black);
          border-radius: 50px;
          transition: var(--quick);
          border: 3px solid var(--black);

          &:hover {
            background: var(--white);
            color: var(--black);
          }
        }
      }
    }

    .right {
      background: var(--lightblue);
      width: 45%;
      height: 40%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        margin: 5px 0px;
      }

      a {
        margin-top: 20px;
        background-color: #2557a7;
        padding: 15px 30px;
        color: var(--white);
        border-radius: 5px;
        transition: var(--quick);
        border: 3px solid #2557a7;
        font-weight: 600;

        &:hover {
          background: var(--white);
          color: #2557a7;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .apply-container {
    height: 130vh;
    
    .apply-box {
        flex-direction: column;

      .left {

        width: 100% !important;
        height: 75% !important;
        align-items: center;
        justify-content: flex-start !important;

        p{
            max-width: 80%;
            margin: 10px 0px;
        }

      }
      .right {
        width: 100% !important;
        height: 20% !important;
        margin-top: 30px;
      }
    }
  }
}
