.about-container {
  height: 100vh;
  width: 100vw;
  background: var(--white);
  padding: 20px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.about-container .about-box {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--boundry);
}
.about-container .about-box .left {
  width: 55%;
  height: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-container .about-box .left h2 {
  font-size: 50px;
}
.about-container .about-box .left p {
  text-align: justify;
  margin: 10px 0px;
}
.about-container .about-box .left a {
  margin-top: 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.about-container .about-box .right {
  width: 45%;
  height: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .about-container {
    height: 130vh;
  }
  .about-container .about-box {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .about-container .about-box .left {
    width: 100% !important;
    height: 70% !important;
    align-items: center;
    padding: 5px !important;
  }
  .about-container .about-box .left p {
    text-align: justify !important;
  }
  .about-container .about-box .left h2 {
    font-size: 32px !important;
  }
  .about-container .about-box .right {
    width: 70% !important;
    height: 30% !important;
  }
  .about-container .about-box .right img {
    padding-bottom: 50px;
  }
}/*# sourceMappingURL=about.css.map */