.homepage {
  height: fit-content;
  width: 100vw;
  background: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  section {
    width: 100vw;
    &:nth-child(even) {
      background: var(--lightblue);
    }
  }
}

.sec1 {
  position: relative;
  height: 100vh;
  background-position: center center;
  display: grid;
  place-items: center;

  .hero-box {
    width: 100vw;
    height: 100vh;
    display: flex;
    max-width: var(--boundry);
    position: relative;

    .left {
      height: 100%;
      width: 50%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 60px;
      }
      p {
        font-size: 20px;
        text-align: justify;
      }

      .btn-box {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 60%;
      }
    }

    .right {
      padding: 20px;
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        height: 60%;
        width: auto;
      }
    }
  }

  .bg {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.247);
    position: absolute;
    top: 0px;
    left: 0pc;
  }
}

.sec2 {
  height: 60vh;
  position: relative;
  background-position: center center;
  display: grid;
  place-items: center;

  .wcu-box {
    width: 100vw;
    height: 80%;
    display: flex;
    flex-direction: column;
    max-width: var(--boundry);
    position: relative;

    .top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .bottom {
      height: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      align-items: center;
      max-width: 100% !important;

      .smallbox {
        padding: 10px;
        display: flex;
        width: 450px;
        border: 3px solid var(--blue);
        border-radius: 10px;
        margin: 20px;

        .icon {
          height: 100%;
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          animation-name: floating;
          animation-duration: 3s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          margin-left: 30px;
          margin-top: 5px;
          color: var(--blue);

          @keyframes floating {
            0% {
              transform: translate(0, 0px);
            }
            50% {
              transform: translate(0, 5px);
            }
            100% {
              transform: translate(0, -0px);
            }
          }
        }
        .text {
          width: 65%;
          p{
            text-align: justify !important;
          }

          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }

  .bg {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.247);
    position: absolute;
    top: 0px;
    left: 0pc;
  }
}

.sec2-1 {
  background: var(--white) !important;
  height: 80vh;
  padding-bottom: 0px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center ;

  .mission-container {
    width: 100vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    max-width: var(--boundry);
    position: relative;
    margin: 5px;

    .top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      h2{
        text-align: center;
      }
    }

    .bottom {
      height: 80%;
      display: flex;
      align-items: center;
      max-width: 100% !important;

      .mission-left{
        width: 50%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        .mission-box{
          width: 90%;
          height: 130px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          position: relative;
          overflow: hidden !important;
          border: 3px solid var(--blue);
          border-radius: 10px;
          animation: rotate 5s ease-in-out infinite;
          &:nth-child(2){
            animation-delay: 2s;
          }
          &:nth-child(3){
            animation-delay: 3.5s;
          }

          @keyframes rotate {

            0% { transform: rotateY(0);}
            25% {transform: rotateY(10deg);}
            50% {transform: rotateY(0);}
            75% {transform: rotateY(-10deg);}
            100%{transform: rotateY(0);}
            
            
          }

          .topic{
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box !important;
            width: 30%;
            font-size: 150%;
            height: 150px;
            text-align: center;
            color: var(--white);
            background: var(--blue);

          }
          .data{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 70%;
            height: 100%;

            ul{
              li{
                padding: 3px;

              }
            }
          }
        }

      }

      .mission-right{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .vision-box {
          width: 80%;
          background: var(--lightblue);
          padding:20px 50px;
          border-radius: 10px;
          &:nth-child(1){
            margin-bottom: 50px;
          }

          p{
            text-align: center;
            margin-top: 10px;
            text-align: justify !important;
          }
        }


      }


    }
  }
}

.sec3 {
  height: 60vh;
  position: relative;
  background-position: center center;
  display: grid;
  place-items: center;

  .gallery-box {
    width: 100vw;
    height: 80%;
    display: flex;
    flex-direction: column;
    max-width: var(--boundry);
    position: relative;

    .top {
      height: 10%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .bottom {
      height: 90%;
      width: 100% !important;

      .imgss-box {
        height: 100%;
        width: 100%;
        display: flex;
        overflow-x: scroll;
        align-items: center;

        .slide {
          flex: 0 0 300px;
          margin: 10px;
          height: 300px;
          width: 300px;
          border-radius: 10px;
          background-size: cover !important;

          &:nth-child(1) {
            background: url("../img/raw/1.jpg");
          }

          &:nth-child(2) {
            background: url("../img/raw/2.jpg");
          }

          &:nth-child(3) {
            background: url("../img/raw/3.jpg");
          }

          &:nth-child(4) {
            background: url("../img/raw/4.jpg");
          }

          &:nth-child(5) {
            background: url("../img/raw/5.jpg");
          }

          &:nth-child(6) {
            background: url("../img/raw/6.jpg");
          }

          &:nth-child(7) {
            background: url("../img/raw/7.jpg");
          }

          &:nth-child(8) {
            background: url("../img/raw/8.jpg");
          }

          &:nth-child(9) {
            background: url("../img/raw/9.jpg");
          }

          &:nth-child(10) {
            background: url("../img/raw/10.jpg");
          }
        }
      }
    }
  }
}

.sec4 {
  height: 70vh;
  position: relative;
  background-position: center center;
  display: grid;
  place-items: center;

  .faq-box {
    width: 100vw;
    height: 80%;
    display: flex;
    flex-direction: column;
    max-width: var(--boundry);
    position: relative;
    justify-content: center;

    .top {
      height: 10%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .bottom {
      height: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      align-items: center;
      max-width: 100% !important;
      padding: 20px;

      .faq {
        min-width: 100%;
        margin: auto;
        border: 1px solid var(--black);
        padding: 15px 20px;
        position: relative;
        margin: 10px;
        transition: var(--quick);

        .arrow {
          height: 30px;
          width: 30px;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          right: 0px;
          transition: var(--quick);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
        }
        h3,
        p {
          transition: var(--quick);
        }
        h3 {
          font-family: sans-serif;
          font-weight: 700;
          font-style: italic;
        }

        &.faq-open {
          background: var(--black);
          color: var(--white);
          .arrow {
            transform: translateY(-50%) rotate(180deg);
            top: 50%;
          }

          p {
            font-size: 16px;
            margin-top: 20px;
            text-align: left !important;
            max-width: 98%;
          }
        }

        &.faq-close {
          .arrow {
            bottom: 0px;
          }

          p {
            font-size: 0px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .hero-box {
    flex-direction: column-reverse;
    margin-top: 60px;

    .left {
      width: 100% !important;
      height: 60% !important;
      align-items: center;
      justify-content: flex-start !important;

      P {
        max-width: 95%;
        margin: 10px 0px;
        text-justify: distribute-all-lines !important;
      }
      .btn-box {
        margin-top: 20px !important;
        width: 90% !important;
      }
    }
    .right {
      width: 100% !important;
      height: 40% !important;

      img {
        height: 100% !important;
        width: auto !important;
      }
    }
  }

  .sec2 {
    height: 110vh;
    .wcu-box {
      height: 100%;

      .text {
        p {
          text-align: left !important;
        }
      }
    }
  }

  .sec2-1 {
    height: 140vh !important;
    .top{
      height: 5% !important;
    }
    .bottom{
      height: 95% !important;
      flex-direction: column;
    }

    .mission-left{
      width: 100% !important;
      height: 50% !important;
      align-items: center !important;

      .mission-box{
        height: 105px !important;

        .topic{
          font-size: 100% !important;
          width: 25% !important;
        }

        .data {
          width: 75% !important;
          ul{
            li{
              font-size: 13px;
            }
          }
        }


      }


    }
    .mission-right{
      width: 100% !important;
      height: 50% !important;

      .vision-box{
        padding:20px 30px !important;

        height: fit-content !important;
        width:90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:nth-child(1){
          margin-top: 50px;
        }
      }


    }
  }
  .sec4 {
    height: 110vh;
    .faq-box {
      height: 100%;
      justify-content: center !important;

      .top {
        height: 7%;
      }
    }
  }
}
