.about-container {
  height: 100vh;
  width: 100vw;
  background: var(--white);
  padding: 20px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .about-box {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--boundry);

    .left {
      width: 55%;
      height: 80%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-size: 50px;
      }

      p {
        text-align: justify;
        margin: 10px 0px;
      }
      a {
        margin-top: 20px;
        width: fit-content;
      }
    }

    .right {
      width: 45%;
      height: 80%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .about-container{
    height: 130vh;
    .about-box {
      flex-direction: column-reverse;
      justify-content: flex-start;
  
      .left {
        width: 100% !important;
        height: 70% !important;
        align-items: center;
        padding: 5px !important;
  
        p{
          text-align: justify !important;
        }
  
        h2{
          font-size: 32px !important;
        }
      }
      .right {
        width: 70% !important;
        height: 30% !important;
  
        img{
          padding-bottom: 50px;
        }
      }
    }
  }
}
