.footer_parent {
  width: 100vw;
  background: var(--black) !important;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  justify-content: space-evenly;
}
.footer_parent .copy {
  opacity: 0.3;
  font-size: 14px;
  color: var(--white);
  max-width: var(--boundry);
  margin: 10px auto;
  text-align: center;
  height: 50px;
}
.footer_parent .copy a {
  text-decoration: none;
  color: white;
}
.footer_parent .footer {
  max-width: var(--boundry);
  margin: auto;
  color: var(--white);
  font-family: sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
}
.footer_parent .footer .col {
  padding: 20px;
}
.footer_parent .footer .col1 {
  width: 45%;
}
.footer_parent .footer .col1 .logo {
  display: flex;
  align-items: center;
  font-family: "Bebas Neue", sans-serif;
  font-size: 40px;
}
.footer_parent .footer .col1 .logo img {
  height: 60px;
  margin-right: 20px;
}
.footer_parent .footer .col1 p.desc {
  color: rgba(255, 255, 255, 0.253);
  font-weight: 100;
  font-family: sans-serif;
  padding: 20px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.199);
}
.footer_parent .footer .col1 .social {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}
.footer_parent .footer .col1 .social a {
  color: rgba(255, 255, 255, 0.253);
  font-size: 25px;
  transition: var(--quick);
  padding: 5px 10px;
}
.footer_parent .footer .col1 .social a:nth-child(2) {
  font-size: 30px;
}
.footer_parent .footer .col1 .social a:nth-child(3) {
  font-size: 25px;
}
.footer_parent .footer .col1 .social a:hover {
  color: var(--blue);
}
.footer_parent .footer .col2 {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
}
.footer_parent .footer .col2 .footer-header {
  color: var(--blue);
  font-size: 150%;
  margin: 0px 0px 10px 0px;
  font-family: "Bebas Neue", sans-serif;
}
.footer_parent .footer .col2 a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.253);
  transition: var(--quick);
  padding: 10px 0px;
}
.footer_parent .footer .col2 a:hover {
  color: var(--white);
}
.footer_parent .footer .col3 {
  width: 30%;
}
.footer_parent .footer .col3 .footer-header {
  color: var(--blue);
  font-size: 150%;
  margin: 0px 0px 10px 0px;
  font-family: "Bebas Neue", sans-serif;
}
.footer_parent .footer .col3 iframe {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .footer_parent {
    height: 90vh;
  }
  .footer_parent .footer {
    flex-direction: column;
    position: relative;
    width: 100vw;
  }
  .footer_parent .col {
    width: 100% !important;
  }
  .footer_parent .footer-header {
    display: none;
  }
  .footer_parent .col1 .social {
    align-items: center;
    justify-content: center;
  }
  .footer_parent .col2 {
    flex-direction: row !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.199);
    border-top: 1px solid rgba(255, 255, 255, 0.199);
  }
}/*# sourceMappingURL=footer.css.map */