.header-parent {
  position: absolute;
  top: 20px;
  left: 0px;
  height: 70px;
  width: 100vw;
  position: fixed;
  z-index: 100;
}
.header-parent .header-container {
  display: flex;
  height: 60px;
  max-width: var(--boundry);
  margin: 5px auto;
  justify-content: space-between;
  align-items: center;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  background: rgba(178, 214, 223, 0.473);
  padding: 10px 0px;
  border: 0.5px solid rgba(255, 255, 255, 0.226);
  position: relative;
  border-radius: 10px;
}
.header-parent .header-container .logo {
  width: 20%;
  margin-left: 10px;
  transition: var(--quick);
}
.header-parent .header-container .logo:hover img {
  height: 50px;
}
.header-parent .header-container .logo img {
  height: 40px;
  transition: var(--quick);
}
.header-parent .header-container .links {
  display: flex;
  align-items: center;
  overflow: hidden !important;
  height: 60px;
  width: 40%;
  justify-content: space-around;
}
.header-parent .header-container .links a {
  overflow: hidden !important;
  text-decoration: none;
  padding: 30px 20px;
  color: var(--black);
  overflow: hidden;
  margin: var(--unit);
  position: relative;
  transition: var(--quick);
}
.header-parent .header-container .links a:active, .header-parent .header-container .links a:hover {
  background: var(--black);
  color: var(--white);
}
.header-parent .header-container .btn-container {
  margin-right: 10px;
  width: 20%;
  display: flex;
  justify-content: flex-end;
}
.header-parent .header-container .menu {
  display: none;
}

#header-mob {
  transform: translateX(100%);
  height: 100vh;
  z-index: 100;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background: var(--white);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: var(--quick);
  overflow: hidden;
}
#header-mob.openall {
  transform: translateX(0%);
}
#header-mob.closeall {
  transform: translateX(100%);
}
#header-mob .close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 40px;
  transition: var(--quick);
}
#header-mob .close :hover {
  transform: rotate(180deg);
}
#header-mob .links-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#header-mob .links-mob a {
  text-decoration: none;
  color: var(--black);
  font-size: 32px;
  font-weight: 500;
  padding: 20px 40px;
  font-family: "Bebas Neue", sans-serif;
  margin: 20px 0px;
}
#header-mob .links-mob a:active {
  background: var(--black);
  color: var(--white);
}

@media only screen and (max-width: 1000px) {
  .header-parent {
    top: 0px;
    height: 50px;
  }
  .header-parent .header-container {
    margin: 10px 10px;
  }
  .header-parent .header-container .logo:hover img {
    height: 40px;
  }
  .header-parent .header-container .logo img {
    height: 40px;
  }
  .header-parent .header-container .links,
  .header-parent .header-container .btn-container {
    display: none !important;
  }
  .header-parent .header-container .menu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-right: 10px;
    transform: rotateY(180deg);
  }
}/*# sourceMappingURL=header.css.map */