:root {
    --white: #F2FBFC;
    --boundry: 1200px;
    --slow: all 1s ease-in-out;
    --med: all 0.5s ease-in-out;
    --quick: all 0.3s ease-in-out;
    --black: #092123;
    --lightblue: #dbf0f0;
    --blue: #1FB6BC;
    --blue2: #79cbce;
    --yellow: #e0a839;
    --unit: 20px;
    --opacity: 0.5;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0px;
    // border: 1px dotted red;
  }
  
  
  
  
  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
  
  h1,h2,h3,h4 {
    font-family: 'Bebas Neue', sans-serif;
    // letter-spacing: 1px;
    span{
      color: var(--blue);
    }
  
  }
  
  a{
    text-decoration: none;
    font-family: sans-serif;
    font-weight: 400;
    text-transform: uppercase;

  }
  
.btn-main {
    padding: 8px 20px;
    color: var(--white);
    background: var(--black);
    border-radius: 50PX;
    transition: var(--quick);
    border: 3px solid var(--black);

    &:hover{
        background: var(--white);
        color: var(--black);
    }
}

.btn-invert{

    padding: 8px 20px;
    color: var(--black);
    background: var(--white);
    border-radius: 50PX;
    transition: var(--quick);
    border: 3px solid var(--black);

    &:hover{
        background: var(--black);
        color: var(--white);
    }
    
}

body{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}


h2.title{
  font-size: 32px;
  text-align: center;
}
p.strong{
  font-size: 22px;
  font-weight: 600;
  span{
    color: var(--blue);
  }
    
}


@media only screen and (max-width: 1000px) {
  p{
    font-size: 13px !important ;
    text-align: center !important;

    &.strong{
      font-size: 16px !important ;
    }
  }
  h1{
    font-size: 32px !important;
    text-align: center;
  }
  h2.title{
    font-size: 34px;
  }
}