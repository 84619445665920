.header-parent {
  position: absolute;
  top: 20px;
  left: 0px;
  height: 70px;
  width: 100vw;
  position: fixed;
  z-index: 100;
  

  .header-container {
    display: flex;
    height: 60px;
    max-width: var(--boundry);
    margin: 5px auto;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(7px);
    background: rgba(178, 214, 223, 0.473);
    padding: 10px 0px;
    border: 0.5px solid rgba(255, 255, 255, 0.226);
    position: relative;
    border-radius: 10px;
    .logo {
        width: 20%;
      margin-left: 10px;
      transition: var(--quick);
      &:hover {
        img {
          height: 50px;
        }
      }
      img {
        height: 40px;
        transition: var(--quick);
      }
    }

    .links {
      display: flex;
      align-items: center;
      overflow: hidden !important;
      height: 60px;
      width: 40%;
      justify-content: space-around;

      a {
        overflow:  hidden !important;
        text-decoration: none;
        padding: 30px 20px;
        color: var(--black);
        overflow: hidden;
        margin: var(--unit);
        position: relative;
        transition: var(--quick);
        &:active,&:hover {
            background: var(--black);
            color: var(--white);
          }
        
      }
    }

    .btn-container {
      margin-right: 10px;
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }
    .menu {
      display: none;
    }
  }
}

#header-mob {
  transform: translateX(100%);
  height: 100vh;
  z-index: 100;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background: var(--white);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: var(--quick);
  overflow: hidden;

  &.openall {
    transform: translateX(0%);
  }
  &.closeall {
    transform: translateX(100%);
  }
  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 40px;
    transition: var(--quick);
    :hover {
      transform: rotate(180deg);
    }
  }

  .links-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      text-decoration: none;
      color: var(--black);
      font-size: 32px;
      font-weight: 500;
      padding: 20px 40px;
      font-family: "Bebas Neue", sans-serif;
      margin: 20px 0px;

      &:active {
        background: var(--black);
        color: var(--white);
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .header-parent {
    top: 0px;
    height: 50px;
    .header-container {

      margin: 10px 10px;
      .logo {

        &:hover {
            img {
              height: 40px;
            }
          }
        
        img {
          height: 40px;
        }
      }

      .links,
      .btn-container {
        display: none !important;
      }

      .menu {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        margin-right: 10px;
        transform: rotateY(180deg);
      }
    }
  }
}
