.footer_parent {

    width: 100vw;
    background: var(--black)!important ;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    justify-content: space-evenly;
    

    .copy{
        opacity: 0.3;
        font-size: 14px;
        color: var(--white);
        max-width: var(--boundry);
        margin: 10px  auto ;
        text-align: center;
        height: 50px;

        a{
            text-decoration: none;
            color: white;
        }

    }



    .footer{
        max-width: var(--boundry);
        margin: auto;
        color: var(--white);
        font-family: sans-serif;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid  rgba(255, 255, 255, 0.11);

        .col{
            padding: 20px;
        }

        .col1{
            width: 45%;
            .logo {
                display: flex;
                align-items: center;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 40px;
                img{
                    height: 60px;
                    margin-right: 20px;
                }
            }
            p.desc{
                color: rgba(255, 255, 255, 0.253);
                font-weight: 100;
                font-family: sans-serif;
                padding: 20px 0px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.199);
                
            }

            .social{
                padding: 10px 0px ;
                display: flex;
                align-items: center;
                

                a{
                    color: rgba(255, 255, 255, 0.253);
                    font-size: 25px;
                    transition: var(--quick);
                    padding: 5px 10px;
                    &:nth-child(2){
                        font-size: 30px;
                    }
                    &:nth-child(3){
                        font-size: 25px;
                    }

                    &:hover{
                        color: var(--blue);
                    }
                }
            }
            
            
        }
        .col2{
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
            height: 100%;

            .footer-header{
                color: var(--blue);
                font-size: 150%;
                margin: 0px 0px 10px 0px;
                font-family: 'Bebas Neue', sans-serif;

            }

            a{
                text-decoration: none;
                color: rgba(255, 255, 255, 0.253);
                transition: var(--quick);
                padding: 10px 0px;
                &:hover{
                    color: var(--white);
                }
            }
            
        }
        .col3{
            width: 30%;

            .footer-header{
                color: var(--blue);
                font-size: 150%;
                margin: 0px 0px 10px 0px;
                font-family: 'Bebas Neue', sans-serif;

            }
            iframe{
                height: 100%;
                width: 100%;
                border: none;
                border-radius: 5px;
            }
            
        }
       
    }



}






@media only screen and (max-width: 768px) {
    .footer_parent{
        height: 90vh;
        .footer{
            flex-direction: column;
            position: relative;
            width: 100vw;
        }
        .col{
            width: 100% !important;
        }
        .footer-header{
            display: none;
        }
    
        .col1{
            .social{
                align-items: center; 
                justify-content: center;
            }
        }
    
    
        .col2{
            flex-direction: row !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.199);
            border-top: 1px solid rgba(255, 255, 255, 0.199);
                    
            
        }
    }
  }