.contact-container {
  height: 100vh;
  width: 100vw;
  background: var(--white);
  padding: 20px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.contact-container .contact-box {
  height: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 70%;
}
.contact-container .contact-box .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: var(--quick);
  width: 400px;
  border-radius: 10px;
  height: 200px;
  border: 1px solid var(--blue);
  margin: 10px;
}
.contact-container .contact-box .box:hover {
  background: var(--lightblue);
}
.contact-container .contact-box .box .icon {
  font-size: 35px;
  color: var(--blue);
  padding: 10px;
}
.contact-container .contact-box .box a {
  text-decoration: none;
  font-family: "Bebas Neue", sans-serif;
  font-size: 26px;
  color: var(--black);
  transition: var(--quick);
  text-align: center;
}
.contact-container .contact-box .box a span {
  opacity: 0.6;
  font-size: 20px;
  font-family: sans-serif;
}
.contact-container .contact-box .box a:hover {
  color: var(--blue);
}

@media only screen and (max-width: 1200px) {
  .contact-container {
    height: 110vh;
  }
  .contact-container .contact-box {
    width: 90%;
  }
  .contact-container .contact-box .box {
    width: 90%;
    height: 120px;
    padding: 10px 20px;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .contact-container .contact-box .box .icon {
    font-size: 28px;
  }
  .contact-container .contact-box .box a {
    font-size: 20px;
    text-align: left;
  }
  .contact-container .contact-box .box a span {
    font-size: 16px !important;
    line-height: 10px;
  }
}
@media only screen and (max-width: 800px) {
  .contact-container {
    height: 125vh;
  }
  .contact-container .contact-box {
    max-width: 100%;
  }
  .contact-container .contact-box .box {
    width: 90%;
  }
}/*# sourceMappingURL=contact.css.map */